<template>
  <div class="pbf">
     <BrandTabProducts
      :inurl="'/brands/' + props.id + '/products'"
      :brand-id="props.id"
      :category="'nutrient'"
      :intags="{}"
      />
  </div>
</template>

<script setup>
 
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  }
})

</script>

<style scoped>
 

</style>
